/* Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. */
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import page from "./page.js";
import tableHelper from "@/components/dynamic/table/tableHelper.js";

export default {
  mixins: [page, tableHelper],
  components: {
    FileBox: () => import("@/components/fileBox.vue"),
  },
  data: () => ({
    pageData: null,
    recommendList: [],
  }),
  computed: {
    sections() {
      // TODO
      if (!this.pageData) return [];
      return this.pageData.sections;
    },
    anchorList() {
      if (!this.sections) return [];
      return this.sections
        .filter((section) => {
          if (section.type === "form") {
            if (!section.form) return false;
            return section.use_anchor && section.anchor_name;
          }
          return section.use_anchor && section.anchor_name;
        })
        .map((section) => ({
          id: this.$helper.handleAnchorName(section.anchor_name), // 區塊的id
          name: section.anchor_name, // anchor顯示的名稱
        }));
    },
    siteId() {
      return this.$store.getters["base/siteId"];
    },
    code() {
      return this.$route.params.code;
    },
    pageDataId() {
      if (!this.pageData) return "";
      return this.pageData.id;
    },
    name() {
      if (!this.pageData) return "";
      return this.pageData.name;
    },
    content() {
      if (!this.pageData) return "";
      return this.pageData.content;
    },
    files() {
      if (!this.pageData) return [];
      return this.pageData.file;
    },
    filesEmpty() {
      return _isEmpty(this.files);
    },
    isPhotoShow() {
      if (!this.photoUrl) return false;
      if (!this.pageData) return false;
      return !!this.pageData.photo_show_top;
    },
    photoUrl() {
      if (!this.pageData) return "";
      return this.$helper.getPhotoUrl(this.pageData.photo);
    },
    photoName() {
      if (!this.pageData) return "";
      return this.$helper.getPhotoName(this.pageData.photo);
    },
    created_at() {
      if (!this.pageData) return "";
      if (!this.pageData.created_at) return "";
      return this.$helper.moment(this.pageData.created_at).format("YYYY/MM/DD");
    },
    form_id() {
      if (!this.pageData) return "";
      return this.pageData.form_id;
    },
    formData() {
      if (!this.pageData) return null;
      if (_isEmpty(this.pageData.form)) return null;
      if (Array.isArray(this.pageData.form)) return this.pageData.form[0];
      return this.pageData.form;
    },
    showForm() {
      if (!this.formData) return false;
      return true;
    },
    formTimeOut() {
      if (!this.formData) return true;
      if (this.formData.show == 0) return true;
      if (!this.formData.end_at) return true;
      const date = this.$helper.moment(this.formData.end_at),
        now = this.$helper.moment();

      return date.isBefore(now);
    },
    secretQuery() {
      return this.$route.query.secret;
    },
    hasSecretLink() {
      if (!this.secretQuery) return false;
      return true;
    },
    preview_id() {
      return this.$route.params.id;
    },
    view_code() {
      return this.$route.query.viewCode;
    },
    hasPreviewLink() {
      if (!this.$route.meta.preview) return false;
      if (!this.preview_id) return false;
      if (!this.view_code) return false;
      return true;
    },
    perRecommendApi() {
      //TODO
      return 3;
    },
    categories() {
      if (!this.pageData) return [];
      if (_isEmpty(this.pageData.category)) return [];
      return this.pageData.category.sort((a, b) => a.sort - b.sort);
    },
    computedRecommendList() {
      // if (!Array.isArray(this.recommendList)) return [];
      // if (this.recommendList.length == 0) return [];
      // // 過濾掉詳細頁這篇的文章
      // const list = this.recommendList.filter(
      //   (item) => item.id != this.pageDataId
      // );
      // // 如果推薦文章沒有這篇,就刪掉最後的文章
      // if (list.length == this.perRecommendApi) list.pop();
      // return list;

      return this.recommendList;
    },
    showRecommend() {
      return !_isEmpty(this.computedRecommendList);
    },
    ImgDetailMax() {
      const { width } = this.$vuetify.breakpoint;
      const { sm } = this.$vuetify.breakpoint.thresholds;
      // if (width >= sm) return "65vw";
      return "750";
    },
    navHeight() {
      return this.$store.getters["base/navHeight"];
    },
    application() {
      return this.$store.getters['base/application']
    },
  },
  watch: {
    $route: {
      deep: true,
      async handler(after, before) {
        // 排除同一頁切換hash的情況
        if (after.path != before.path || !_isEqual(after.query, before.query)) {
          await this.init();
        }
      },
    },
  },
  beforeDestroy() {
    this.cleanAnchor();
    this.$store.commit(`base/resetComponentStatus`);
  },
  async created() {
    await this.init();
    if (this.hasPreviewLink) {
      this.$store.commit(`base/setComponentStatus`, {
        key: "header_menu",
        value: 0,
      });
      this.$store.commit(`base/setComponentStatus`, { key: "logo", value: 2 });
      this.$store.commit(`base/setComponentStatus`, {
        key: "search",
        value: 2,
      });
      this.$store.commit(`base/setComponentStatus`, {
        key: "location",
        value: 2,
      });
      this.$store.commit(`base/setComponentStatus`, {
        key: "breadcrumb",
        value: 2,
      });
      this.$store.commit(`base/setComponentStatus`, {
        key: "footer",
        value: 0,
      });
    }
  },
  methods: {
    async init() {
      await this.getPageData();
      this.getRecommend();
      this.setBreadcrumb();
      await this.handleAfterGetPageData();
      if (this.$route.hash) {
        await this.$helper.delay(1.5);
        this.goToHash();
        return;
      }

      window.scrollTo({
        top: 0,
      });
      await this.$helper.delay(0.2);
      this.$root.$emit("setNavBreadcrumbHeight");
    },
    goToHash() {
      const hash = decodeURI(this.$route.hash);
      const target = $(hash)[0];
      if (!target) return;
      this.$vuetify.goTo(target, {
        offset: this.navHeight,
      });
    },
    async getPageData() {
      this.$store.dispatch("base/loading");
      this.$store.commit(`base/setMainApiLoadDone`, false);
      try {
        if (this.application.site_data.status == 0 ) {
          if ("/"+this.siteId+"/develop" === this.$route.path) {
            return;
          }
          this.$router.replace({ name: "develop" });
          await this.$helper.delay(0.5);
          return;
        }
        if (this.hasSecretLink) {
          this.pageData = await this.api(
            this.siteId,
            this.code,
            this.secretQuery
          );
        } else if (this.hasPreviewLink) {
          this.pageData = await this.api(this.preview_id, this.view_code);
        } else {
          this.pageData = await this.api(this.siteId, this.code);
        }
        this.$store.commit("base/setPageData", this.pageData);
        await this.$nextTick();
        this.wrapTable();
        this.setupMeta();
      } catch (err) {
        console.error(err);
        if (err && err.status == "404") {
          if (!err.data) {
            this.$router.replace({ name: "404" });
            return;
          }
          if (err.data.message == "failed") {
            this.$router.replace({ name: "develop" });
            return;
          }
          this.$router.replace({ name: "404" });
        }
      } finally {
        this.$store.dispatch("base/loading", false);
        this.$store.commit(`base/setMainApiLoadDone`, true);
      }
    },
    async handleAfterGetPageData() {
      await this.$nextTick()
      this.defaultAfterGetPageData();
      this.afterGetPageData();
    },
    defaultAfterGetPageData() {
      this.$root.$emit("initTable");
      this.$root.$emit("setDynamicSubTitle");
    },
    afterGetPageData() {
      // TODO
    },
    async getRecommend() {
      if (!this.recommendApi) return;
      try {
        const is_media = this.$route.name == "media-detail";
        const params = { per: this.perRecommendApi };
        if (is_media) params.is_media = 1;
        const res = await this.recommendApi(this.siteId, this.code, params);
        this.recommendList = res.data;
      } catch (err) {
        console.error(err);
      } finally {
      }
    },
    setAnchor(data) {
      this.$store.commit("base/setAnchor", data);
    },
    cleanAnchor() {
      this.$store.commit("base/setAnchor", null);
    },
  },
};
